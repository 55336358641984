import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About SiCO</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
               
               <h3>Certifications</h3>
                 <ul> 
                   <li><b>ISO 45001:2018</b>(Occupational Health and Safety Management System)  <a target="_blank" rel="noreferrer" href="resources/FinalCertificate45001SpringFieldInternationalCompanyLLC.pdf" >
                       See Details 
                    </a>
                   </li>
                   <li><b>ISO 14001:2015</b>(Environmental Management System) <a target="_blank" rel="noreferrer" href="resources/FinalCertificate14001SpringFieldInternationalCompanyLLC.pdf" >
                      See Details 
                    </a><br/><br/></li>
                 </ul>

              <h3>Our Vision</h3>
              <p>{props.data ? props.data.vision : "loading..."}</p>
              <h3>Our Mission</h3>
              <p>{props.data ? props.data.mission : "loading..."}</p>
              <h3>Our Values</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
